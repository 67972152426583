<template>
  <ThemeHeadPlatForm>
    <slot />
  </ThemeHeadPlatForm>
</template>

<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'
import { usePlacesStore } from '~/store/places'
const { storeTypeModel } = useMenuModel()
const { loadAnnouncements } = useAnnouncementStore()
const { setStoreTypes } = useStoreTypes()
const { fetchCities, fetchAgentPlace } = usePlacesStore()

const allRoutes = getAllRoutes()

async function fetchStoreTypes() {
  await useBasicFetch(allRoutes.storeTypeRoutes.getStoresType, {
    query: { limit: 100, with_category: true, with_material_definitions: 1 },
    transform: (data: SuccessResponse<StoreType[]>) => {
      const dataTemp = data.data.map(storeTypeModel)
      setStoreTypes(dataTemp ?? [])
      return dataTemp
    }
  })
}

await Promise.all([fetchCities(), fetchStoreTypes(), fetchAgentPlace(), loadAnnouncements()])

</script>
